.template-carousel-container {
    text-align: center;
    position: relative;
    max-width: 300px;
    margin: 0 auto;
  }
  
  .carousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  
  .template-card {
    text-align: center;
    cursor: pointer;
    width: 300px;
    margin: 0 auto;
  }
  
  .template-image {
    width: 40%;
    height: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .prev-button,
  .next-button {
    background-color: #007BFF;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #ffffff;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  

  
  .prev-button {
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .next-button {
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  p {
    font-size: smaller;
  }