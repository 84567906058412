.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}


@keyframes popup-fade-in {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Heading styles */
.popup h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #000000; /* Darker text */
}

/* Paragraph styles */
.popup p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.5;
    color: #060606; /* Gray text for readability */
}

/* Email input field styles */
.popup input[type="email"] {
    width:250px;
    padding: 10px;
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    transition: border-color 0.3s ease; /* Smooth transition for border */
}

.popup input[type="email"]:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none;
}

/* Button styles */
.popup button {
    background-color: #3f4041;/* Primary blue color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth hover effect */
    width: 100%;
}

.popup button:disabled {
    background-color: #ccc; /* Gray color for disabled button */
    cursor: not-allowed; /* Change cursor on disabled state */
}

.popup button:hover:not(:disabled) {
    background-color: #0056b3; /* Darker blue on hover */
}

.tos-link{
color: #007bff;
}

.pp-link{
    color: #007bff;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
    .popup {
        padding: 15px;
    }

    .popup h2 {
        font-size: 20px;
    }

    .popup p {
        font-size: 14px;
    }

    .popup button {
        font-size: 14px;
    }
}