.exam-difficulty-box {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px;
}

.difficulty-button {
  padding: 6px 13px; 
  font-size: 0.875rem;
  border: none;
  background-color: #3f4041;;
  color: white;
  border-radius: 3px; 
  cursor: pointer;
  margin: 3px; 
}


.difficulty-button:hover {
  background-color: #6b6c6d; 
}

.difficulty-button.active {
  background-color: #b5c4d5;
  border-color: #6b6c6d;
  color: #fff;
}













