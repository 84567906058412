.layout-body{
  align-items: center;
  background-color: #f0f0f0;
  font-family: 'Arial', sans-serif;
  flex-direction: column;
  height: 100%; 
}

.layout {
  display: flex; 


}

.left-half {
  flex: 1; 
  padding: 0px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  margin-right: 15px;
  margin-left: 20px;
  margin-bottom: 10px;
  min-width: 400px; 
}

.editor{
  padding-bottom: 10px;
  margin-left: 30px;
  
}

.viewer{
  margin-left: 30px;
  
}

.right-half {
  flex: 1; 
  min-width: 400px; 
  max-width: 1000px; 
  padding: 20px;
  font-style: normal;
  text-align: left;
  margin-right: 20px;
  background-color: #f0f0f0;

}




.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
}

.loader-spinner {
  color: #3f51b5; /* Customize spinner color */
}

.loader-text {
  margin-top: 10px;
  font-size: 1rem;
  color: #555; /* Customize text color */
}

.error-text {
  color: red;
  font-size: 1rem;
  margin-top: 10px;
}




/* Back Button */

.button-container {
  position: relative; 
  width: 100%; 
  height: 100%;
}

.circular-back-button {
  position: absolute; /* Position the button inside the container */
  bottom: 20px; /* Move it to the bottom */
  left: 20px; /* Move it to the left */
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.circular-back-button:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}



/* button {
  padding: 6px 13px; 
  font-size: 0.875rem;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 3px; 
  cursor: pointer;
  margin: 3px; 
} */



/* PDF Button */

.pdf-button {
  padding: 6px 13px; 
  font-size: 0.875rem;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 3px; 
  cursor: pointer;
  margin: 3px; 
}


.pdf-button:hover {
  background-color: #0056b3; 
}


.pdf-button:disabled {
  background-color: #c0c0c0; /* Light gray background */
  color: #6c757d; /* Darker gray text */
  cursor: not-allowed; /* Change cursor to indicate it's not clickable */
  opacity: 0.6; 
}

/* Selected Lessons Text  */

.selectedlessons {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  text-align:left;
  }






/* Menu */

.menu-open .left-half {
  transition: margin-left 0.3s ease-in-out;
}

.menu-icon {
  width: 20px; 
  height: 20px; 
  margin-right: 10px; 
  vertical-align: middle; 
}







