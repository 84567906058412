/* General styling for the dialog */
.MuiDialog-root {
    font-family: 'Arial', sans-serif;
}

/* Title styling */
.MuiDialogTitle-root {
    background-color: #3f51b5;
    color: white;
    padding: 16px;
    text-align: center;
}

/* Content styling */
.MuiDialogContent-root {
    padding: 16px;
}

/* Tabs styling */
.MuiTabs-root {
    background-color: #f5f5f5;
    margin-bottom: 16px;
}

.MuiTab-root {
    min-width: 120px;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #3f51b5;
}

.MuiTabs-indicator {
    background-color: #3f51b5;
}

/* New tag styling */
.tab-label {
    display: flex;
    align-items: center;
}

.new-tag {
    background-color: #ff4081;
    color: white;
    border-radius: 12px;
    padding: 2px 6px;
    margin-left: 8px;
    font-size: 10px;
    font-weight: bold;
}

/* List styling */
.MuiListItem-root {
    border-bottom: 1px solid #f0f0f0;
}

.MuiListItem-root:hover {
    background-color: #f5f5f5;
}

.MuiCheckbox-root.Mui-checked {
    color: #3f51b5;
}

/* Button styling */
.MuiButton-containedPrimary {
    background-color: #3f51b5;
    color: white;
    margin-top: 16px;
}

.MuiButton-containedPrimary:hover {
    background-color: #303f9f;
}

.custom-lesson-div {
    display: flex;
    align-items: center;
    gap: 8px; /* Spacing between input and button */
  }
  
  .custom-lesson-input {
    flex-grow: 1; /* Make the input take up available space */
    padding: 8px;
    margin: 8px 0;
    font-size: 16px;
    
  }
  
  .add-custom-lesson-btn {
    padding: 10px 16px; /* Adjust padding for a good button size */; 
    background-color: #3f4041;
  }

  .add-custom-lesson-btn:hover {
    padding: 10px 16px; /* Adjust padding for a good button size */; 
    background-color: #6b6c6d;
  }