
  
  /* Operation buttons styling */
  .operation-buttons-div {
    margin-bottom: 20px;
    display: flex;
    justify-content:center;
  }
  

/* Operation Button Effects */

  .operation-button {
    padding: 6px 13px; 
    font-size: 0.875rem;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 3px; 
    cursor: pointer;
    margin: 3px; 
  }
  
  .operation-button:hover {
    background-color: #0056b3; 
  }
  
  .operation-button.selected {
    background-color: #b5c4d5;
    border-color: #007bff;
    color: #fff;
  }
  
  .inputs-container {
    display: flex;
    flex-direction: column; /* Stack input groups vertically */
    gap: 1rem; /* Add space between each input group */
    align-items: center; /* Horizontally center the input groups */
    justify-content: center; /* Vertically center the input groups */
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .input-group {
    display: flex;
    align-items:start; /* Ensure label and input are aligned vertically */
    gap: 1rem; /* Reduce gap for better alignment */
  }
  
  .first-number, .second-number {
    font-weight: bold; /* Bold labels */
    font-size: small;
    width: 100px; /* Give labels a fixed width */
    text-align: right; /* Right-align the label text */
  }
  
  .input-column-temp {
    width: 60px; /* Set the width to fit exactly 3 digits */
    height: 25px;
    text-align: center; /* Center the digits within the input box */
    font-size: small;

    border: 1px solid #ccc; /* Light border for visibility */
    border-radius: 5px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background color */
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  
  /* Generate button styling */
  .generate-button-div {
    display: flex; /* Flexbox for alignment */
    justify-content: center; /* Center button */
  }
  
  .generate-button {
    background-color: #3f4041; /* Blue background */
    color: white; /* White text */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    padding: 10px 20px; /* Padding */
    font-size: 18px; /* Larger font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .generate-button:hover {
    background-color: #6b6c6d; /* Darker blue on hover */
  }
  
  .generate-button:disabled {
    background-color: #ccc; /* Gray background when disabled */
    cursor: not-allowed; /* Not-allowed cursor when disabled */
  }
  
  .error-msg {
    color: red;
    font-size: 0.875rem;
  }