.account-body{
  font-family: 'Arial', sans-serif;
}


.form-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    gap: 15px;
    margin: 10px 40px;
    font-family: 'Arial', sans-serif;
    gap: 20px;
  }


.profile-title{
    font-size: 20px;
    font-weight: bold;
    margin: 0px;
}


.form-container > div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .name-input, .role-select, .school-input, .country-select {
    flex: 1;
    min-width: calc(30% - 20px); 
  }


  .submit-button {
    background-color: #1976d2;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #1565c0;
  }


  .profile-details{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: calc(50% - 20px);
  }

  .email-input .MuiInputBase-input.Mui-disabled {
    color: black; /* Set the text color to black */
}