.buy-page-body {
    font-family: 'Arial', sans-serif;
}





.div-for-all-pricing {

  display: flex;
  justify-content: center; 
  padding-right: 1rem; /* Top margin */
  padding-left: 1rem; /* Top margin */
  gap: 20px;
  margin: 0 80px;
}

.pricing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 40px;
    flex-wrap: wrap;
}

.pricing-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 300px;
    transition: transform 0.3s ease-in-out;
}

.pricing-card:hover {
    transform: scale(1.05);
}

.pricing-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.pricing-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
}

.pricing-price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #3f4041;
    margin-bottom: 20px;
}



.pricing-button {
    background-color: #3f4041;;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pricing-button:hover {
    background-color: #6b6c6d;
  }


  .pricing-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .spinner {
    width: 16px;
    height: 16px;
    border: 2px solid white;
    border-top: 2px solid transparent;
    border-radius: 50%;
    display: inline-block;
    animation: spin 0.6s linear infinite;
  }
  

  .credits-header {
    display: flex;
    justify-content: center;
    gap: 10px; /* Adds space between img and text */
  }
  
  .credits-icon {
    width: 40px; /* Adjust size as needed */
    height: auto;
  }

  .faq-container {
    max-width: 1020px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.faq-container .MuiAccordion-root {
    background: white;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}

.faq-container .MuiAccordion-root:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.faq-container .MuiAccordionSummary-root {
    font-weight: bold;
    font-size: 1.2rem;
}

.faq-container .MuiAccordionDetails-root {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
}






  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }