.yeargroup-box {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Creates 5 equal-width columns */
  justify-content: center; /* Center align grid horizontally */
  padding-right: 1rem; /* Top margin */
  padding-left: 1rem; /* Top margin */
  gap: 5px;
  margin: 0 80px;
}

.yeargroup-box button {
  padding: 0.25rem 0.5rem; /* Reduce padding */
  border: 2px solid #3f4041;
  border-radius: 5px;
  background-color: #f8f9fa;
  color: #3f4041;;
  font-size: 0.75rem; /* Reduce font size */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transitions */
}

/* Hover effect */
.yeargroup-box button:hover {
  background-color: #3f4041;;
  color: #ffffff;
}

/* Active state */
.yeargroup-box button.active {
  background-color: #3f4041;;
  color: #ffffff;
  border-color: #3f4041;;
}

/* Active hover effect */
.yeargroup-box button.active:hover {
  background-color: #3f4041;;
  border-color: #3f4041;;
}