  .basic_details_count{
    width: 120px;
    font-size:smaller;
    margin: 0; 
    padding: 10px;
    border: 1px solid #ccc; /* Light border for visibility */
    border-radius: 5px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background color */
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-bottom: 10px;
  }

  .basic_details_name{
    width: 120px;
    font-size:smaller;
    margin: 0; 
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc; /* Light border for visibility */
    border-radius: 5px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background color */
    transition: background-color 0.3s ease, transform 0.2s ease;
  }

  .error {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }


  .generate-button-div{
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .card-title{
    padding-top: 10px;
  }

  .add-lessons-button{
    background-color: #3f4041;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-size: .875rem;
    margin: 3px;
    padding: 6px 13px;
  }


  .add-lessons-button:hover {
    background-color: #6b6c6d;
  }

  