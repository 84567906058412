.txt-create-pdfbutton:disabled {
    background-color: #ccc; /* Light gray background */
    color: #666; /* Dimmed text color */
    cursor: not-allowed; /* Show a "blocked" cursor */
    opacity: 0.6; /* Slight transparency to indicate it’s inactive */
}

.txt-create-pdfbutton {
    background-color: #2196f3; /* Blue background */
    color: white; /* White text */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    padding: 8px 19px; /* Padding */
    font-size: 15px; /* Larger font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    margin-bottom: 1rem;
}