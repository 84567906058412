.login-page {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  .login-container {
    display: flex;
    width: 80%;
    height: 80%;
    max-width: 1200px;
    max-height: 800px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .login-left {
    flex: 1;
    background: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .worksheep-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: cover;
  }
  
  .login-right {
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  .login-header {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 300px;
    justify-content: center;
  }
  
  .worksheep-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .worksheep-heading {
    font-size: 1.8rem;
    font-weight: bold;
  }
  
  .auth-wrapper {
    width:50%;
    display: flex;
    flex-direction: column;
  }


.magic-link-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .magic-link-form {
    margin-bottom: 1.5rem;
  }
  
  .email-input {
    width: 72%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .magic-link-button {
    width: 80%;
    padding: 0.8rem;
    background-color: #3fcf8e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .magic-link-button:hover {
    background-color: #34b27b;
  }
  
  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1.5rem 0;
    position: relative;
  }
  
.login-titles{
    margin: 0; /* Remove default margins */
    padding: 0;
}

.login-titles2{
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 0;
}
  .divider::before,
  .divider::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
    margin: 0 1rem;
  }
  
  .line {
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 10px;
  }
  
  .guest-login {
    text-align: center;
  }
  
  .error-message {
    color: red;
  }
  
  .success-message {
    color: green;
  }

  .magic-loader {
    border: 4px solid #f3f3f3; /* Light gray */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: 10px auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .login-container {
      width: 90%;
      height: auto;
      flex-direction: column;
    }
  
    .login-right {
        flex: unset;
        width: 100%;
        padding: 1rem;
      }
    
    .login-left {
        display: none;
      }


    .worksheep-logo {
      width: 40px;
      height: 40px;
    }
  
    .worksheep-heading {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 768px) {
    .login-header {
      flex-direction: column;
      justify-content: center;
    }
  
    .worksheep-heading {
      font-size: 1.2rem;
    }
  
    .email-input {
      width: 100%;
    }
  
    .magic-link-button {
      padding: 0.6rem;
    }
  }
  
  @media (max-width: 480px) {
    .worksheep-logo {
      width: 30px;
      height: 30px;
    }
  
    .worksheep-heading {
      font-size: 1rem;
    }
  
    .magic-link-form {
      margin-bottom: 1rem;
    }
  
    .email-input {
      padding: 0.6rem;
    }
  
    .magic-link-button {
      font-size: 0.9rem;
    }
  }
  