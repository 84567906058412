.questions-preview-container {
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    margin: 0 auto;
    max-height: 200px; /* Set a fixed height */
    overflow-y: auto;  /* Enable vertical scroll */
  }
  
  .question-item {
    margin-bottom: 12px; /* Reduce space between questions */
    padding: 3px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .question-item:last-child {
    margin-bottom: 0;
  }
  
  .question-latex h1, .question-text p {
    font-size: 1.1rem;  /* Adjust font size */
    line-height: 1.4;   /* Reduce line spacing */
    color: #333;
  }
  
  .mathjax-container {
    padding: 8px;
    background-color: white;
    border-radius: 5px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  

  .placeholder {
    text-align: center;
    color: #6c757d; /* Subtle grey text */
    font-style: italic;
    padding: 2rem;
    background-color: #e9ecef;
    border-radius: 8px;
    margin: 2rem 0; /* Adds space around the placeholder */
  }