/* Navbar container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: #ffffff;
  font-family: 'Arial', sans-serif;
}


.navbar_title {
  font-size: 1.25rem;
  line-height: 1.75rem; 
  font-weight: 700;
  font-family: "Varela Round", sans-serif;
  color: #000000;
}


.navbar_links {
  display: flex;
  list-style-type: none;
  font-family: "Varela Round", sans-serif;
  margin: 0;
  padding: 0;
}


.hamburger {
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  cursor: pointer;
}



.p4 {
  padding: 0.5rem; 
}

.navbar_links li a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
  font-size: 0.875rem; /* Reduced font size */
}

.navbar_links li a:hover {
  color: #007BFF;
  cursor: pointer;
  
}

/* Button styling */
.allbutton {
  width: 120px; /* Reduced button width */
  padding: 0.370rem 0.75rem; /* Reduced padding */
  border-radius: 0.25rem; /* Reduced border-radius */
  margin: 0.25rem auto; /* Reduced margin */
  background-color: #007BFF;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.allbutton:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.navbar_logo {
  height: 30px; /* Reduced logo height */
  margin-right: 0.5rem; /* Reduced margin */
  padding-left: 5px; /* Adjust padding as needed */
}

/* Logo and title container */
.navbar_logo-title {
  display: flex;
  align-items: center;
}


.beta_container {
  background-color: #71d694;
  color: #090101;
  border-radius: 8px; 
  padding: 0.2rem 0.4rem;
}


.beta_label {
  font-size: 0.75rem; 
  font-weight: 600;
}


.hamburger-menu {
  position: absolute; 
  top: 75px; 
  left: 0;
  width: 250px;
  height: 100%; 
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 500; 
  padding: 1rem;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

.hamburger-menu.open {
  transform: translateX(0);
}

.close-btn {
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}


.menu-list {
  list-style: none;
  margin: 2rem 0 0;
  padding: 0;
}


a {
  text-decoration: none; 
  color: inherit;   
}


.menu-list li {
  text-decoration: none;
  margin-bottom: 1.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.2s;
  display: flex;
  align-items: center;
}

.menu-list li:hover {
  color: #70d694; 
}

.menu-group {
  display: flex;
  flex-direction: column;
  padding-left: 11px;
}

.menu-group-bottom {
  margin-top: auto; 
}

.menu-divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 1rem 0;
}

.menu-item i {
  margin-right: 10px;
  font-size: 1.5rem; 
}



.navbar-sign-in-btn {
  display: flex;
  margin-left: auto; /* Push the button to the right */
}

.sign-in-btn {
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners */
  padding: 8px 16px; /* Adjust padding */
  font-size: 14px; /* Adjust font size */
  font-weight: 600; /* Bold text */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.sign-in-btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}


.social-icons{
  margin-top: auto;
  display: flex;
  justify-content: center; /* Center icons horizontally */
  gap: 1rem; /* Space between icons */
  padding: 1rem 0;
  background-color: #f8f9fa;
}

.social-icons img {
  width: 24px;
  height: 24px;
  transition: transform 0.2s;
}


.social-icons img:hover {
  transform: scale(1.2); /* Slight zoom effect on hover */
}



.navbar-worksheets-left {
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Optional: Center text horizontally if needed */
  position: relative; /* Ensure stacking context for any absolute positioning */
  margin-left: 1rem; /* Add space to the left of the email */
  font-family: 'Arial', sans-serif;
  font-size: 0.875rem; /* Match the style with navbar */
  font-weight: 600;
  color: #ffffff;
  background-color: #3f4041; /* Optional: Add a subtle background color */
  padding: 0.2rem 0.5rem; /* Adjust padding to fit content */
  border-radius: 4px; /* Smooth the edges */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a slight shadow */
  z-index: 10; /* Ensure it appears on top of sibling elements */
}