

.geo-difficulty-box {
  display: flex;
  flex-direction: column; 
  gap: 10px; 
  padding: 10px;
  margin: 0 110px;
}

.difficulty-item {
  display: flex;
  flex-direction: row; 
  align-items:center;
  gap: 5px; /* Add spacing between button and label */
}

.geo-difficulty-label {
  flex-grow: 1;
  font-size: 0.8rem;
  color: #555; /* Subtle text color */
  margin-bottom: 0.25rem; /* Space between label and button */
}

.geo-difficulty-button {
  padding: 6px 13px; 
  font-size: 0.875rem;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 3px; 
  cursor: pointer;
  margin: 3px; 
}

.geo-difficulty-button:hover {
  background-color: #0056b3; 
}

.geo-difficulty-button.active {
  background-color: #b5c4d5;
  border-color: #007bff;
  color: #fff;
}


.beta_container {
  background-color: #71d694; /* Subtle green for beta tag */
  color: #fff; /* White text for better contrast */
  border-radius: 12px; /* Rounded tag appearance */
  padding: 0.1rem 0.3rem; /* Smaller padding for compactness */
  display: inline-block; /* Ensures it wraps around the label tightly */
  font-size: 0.9rem; /* Slightly smaller font size */
  font-weight: 600; /* Bold text */
  text-transform: uppercase; /* Makes the text all caps for a tag look */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.beta_label {
  font-size: inherit; /* Inherits the font size from the container */
  line-height: 1; /* Compact vertical spacing */
}